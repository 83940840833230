import { application } from "../init/stimulus";

function loadContext(context) {
  context.keys().forEach((path) => {
    const mod = context(path);

    // Check whether a module has the Controller export defined
    if (!mod.Controller) return;

    // Convert path into a controller identifier:
    //   example/index.js -> example
    //   nav/user_info/index.js -> nav--user-info
    const identifier = path.replace(/^\.\//, "")
      .replace(/\/index\.js$/, "")
      .replace(/\//, "--");

    application.register(identifier, mod.Controller);
  });
}

export default loadContext;
export { loadContext };
