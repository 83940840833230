import { Controller } from "stimulus";

const debounce = require("lodash.debounce");

export default class extends Controller {
  static targets = ["form", "nameInput", "searchIcons"];

  initialize() {
    this.handleTextInput = debounce(this.handleTextInput, 350).bind(this);
  }

  connect() {
    // Stimulus does not work with Select2 events, workaround here
    // eslint-disable-next-line func-names
    $(".select2").on("select2:select select2:unselect", function () {
      const event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });
    this.searchIconsTarget.classList.remove("loading");
  }

  finish() {
    this.stopProgress();
    this.searchIconsTarget.classList.remove("loading");
  }

  handleTextInput(event) {
    // Clear text on esc key press.
    if ((event.keyCode || event.which) === 27) {
      this.nameInputTarget.value = "";
    } else {
      this.searchIconsTarget.classList.add("loading");
      this.formSubmit();
    }
  }

  formSubmit() {
    this.startProgress();

    // Without this delay, the form submits old value on select2 option unselect,
    // FIXME: debug more to see why this happens and remove setTimeout.
    setTimeout(() => {
      Rails.fire(this.formTarget, "submit");
    }, 50);
  }

  startProgress() {
    if (!Turbolinks.supported) return;

    Turbolinks.controller.adapter.progressBar.setValue(0);
    Turbolinks.controller.adapter.progressBar.show();
  }

  stopProgress() {
    if (!Turbolinks.supported) return;

    Turbolinks.controller.adapter.progressBar.setValue(100);
    Turbolinks.controller.adapter.progressBar.hide();
  }
}
