import { Controller } from "stimulus";

export default class extends Controller {
  /**
   * GTAG dataLayer event format type
   * @typedef {{ eventCategory: string, event: string, eventAction: string, eventLabel: string | null }} TrackingEvent
  */

  trackClick(event) {
    const parsedEvent = this.parseEvent("Click", event.params.click);
    if (parsedEvent == null) return;

    window.dataLayer.push(parsedEvent);
  }

  trackCopy(event) {
    const parsedEvent = this.parseEvent("Copy", event.params.copy);
    if (parsedEvent == null) return;

    window.dataLayer.push(parsedEvent);
  }

  /**
   * Parses event string in form "category->action#label" to TrackEvent type.
   * @example
   *  parseEvent("Copy", "aaa->bbb#ccc")
   *  // returns { event: "genericCopy", eventCategory: "aaa", eventAction: "bbb", eventLabel: "ccc" }
   *
   * @param {string} eventType
   * @param {string} eventParams
   *
   * @returns {TrackingEvent | null}
   */

  parseEvent(eventType, eventParams) {
    if (eventParams == null || eventParams === "") {
      return null;
    }

    const [category, rest] = eventParams.split("->");
    const [action, label] = rest.split("#");

    return {
      event: `generic${eventType}`,
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    };
  }
}
